
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.
@import "ecsy-palette";
@import "primeicons/primeicons.css";
@import "primeflex/primeflex.css";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ecsy-primary: mat.m2-define-palette($md-ecsy);
$ecsy-accent: mat.m2-define-palette($md-ecsy, A200, A100, A400);

// The warn palette is optional (defaults to red).
$ecsy-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$ecsy-theme: mat.m2-define-light-theme((
  color: (
    primary: $ecsy-primary,
    accent: $ecsy-accent,
    warn: $ecsy-warn,
  ),
  density: -2
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($ecsy-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { 
  margin: 0; 
  font-family: "Open Sans", Roboto, "Helvetica Neue", sans-serif; 
  outline: none; 
  overflow: hidden;
}

:root {
  // --xc-header-height: 40px;
  // --xc-footer-height: 24px;
  --xc-side-panel-header-height: 30px;
  --xc-schedule-col-default-width: 20px;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  border-radius: 30px;
  background-color: #3d82ff5e;
}

